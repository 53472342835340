<template>
  <div>
    <div
      class="calendar-container"
      :style="
        isPreview ? `background-color: #f7f7f7;` : `border: 1px solid #e2e2e2;`
      "
    >
      <div
        :class="
          isPreview ? `calendar-container-header` : `calendar-container__header`
        "
      >
        <span
          v-for="(item, index) in weekDaysByMode"
          :key="'calendar-day' + index"
        >
          <span class=" day-color">{{ item.name }}</span>
          <span v-if="!isCalendarModeMonth" class="ml-1">
            {{ item.date }}
          </span>
        </span>
      </div>
      <hr v-if="isPreview" class="ml-3 mr-3 separator" />
      <div class="calendar-container__body">
        <template v-if="isCalendarModeMonth">
          <div
            v-for="(week, i) in daysOfTheCurrentMonthByWeeks"
            :key="`week_${i}`"
            :class="
              isPreview
                ? `calendar-container-week ml-3`
                : `calendar-container__week `
            "
          >
            <div
              v-for="(day, j) in week"
              :key="`day_${j}`"
              :class="
                isPreview
                  ? `calendar-container-day-edit`
                  : `calendar-container__day`
              "
            >
              <div :class="isToday(day) && isPreview ? `circle mb-0` : ``">
                <span
                  :style="
                    isPreview
                      ? `text-align: center; vertical-align: middle;`
                      : ``
                  "
                >
                  {{ day.getDate() }}
                </span>
              </div>
              <CalendarEventCard
                v-b-modal.calendar-event-details-modal
                :events="getEventsByDay(day)"
                :is-mobile="isMobile"
                :is-preview="isPreview"
                @on-select-day-view="$emit('on-select-day-view', day)"
                @on-select-event="selectedEventModalData = $event"
                @show-events="showEvents"
              />
            </div>
          </div>
        </template>
        <template v-else-if="isCalendarModeWeek">
          <div
            v-for="({ hour, days }, i) in daysOfTheCurrentWeekByHours"
            :key="`hour_${i}`"
            class="calendar-container__weekView"
          >
            <div class="calendar-container__weekViewHour">
              <span>{{ hour.name }}</span>
            </div>
            <div
              v-for="(day, j) in days"
              :key="`day_${j}`"
              class="calendar-container__dayView"
            >
              <CalendarEventCard
                v-b-modal.calendar-event-details-modal
                :events="getEventsByDayAndHour(day, hour)"
                @on-select-event="selectedEventModalData = $event"
                @show-events="showEvents"
              />
            </div>
          </div>
        </template>
        <template v-else-if="isCalendarModeDay">
          <div
            v-for="({ hour, day }, i) in dayHoursWithCurrentDate"
            :key="`hour_${i}`"
            class="calendar-container__weekView"
          >
            <div class="calendar-container__weekViewHour">
              <span>{{ hour.name }}</span>
            </div>
            <div class="calendar-container__dayView">
              <CalendarEventCard
                v-b-modal.calendar-event-details-modal
                :events="getEventsByDayAndHour(day, hour)"
                @on-select-event="selectedEventModalData = $event"
                @show-events="showEvents"
              />
            </div>
          </div>
        </template>
      </div>
    </div>
    <calendar-event-details-modal
      v-if="!isPreview"
      :event="selectedEventModalData"
      :is-compliance-type="isComplianceType"
      name="calendar-event-details-modal"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import CalendarEventCard from './CalendarEventCard';
import CalendarEventDetailsModal from './CalendarEventDetailsModal';
import { CALENDAR_MODE, DAYS_OF_WEEK, DEFAULT_HOURS } from '../constants';

export default {
  name: 'CalendarBody',
  components: {
    CalendarEventCard,
    CalendarEventDetailsModal,
  },
  props: {
    isPreview: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    mode: { type: String, default: null },
    currentDate: { type: Date, default: null },
    eventList: { type: Array, default: () => [] },
    isComplianceType: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    selectedEventModalData: null,
  }),
  computed: {
    isCalendarModeMonth() {
      return this.mode === CALENDAR_MODE.MONTH;
    },
    isCalendarModeWeek() {
      return this.mode === CALENDAR_MODE.WEEK;
    },
    isCalendarModeDay() {
      return this.mode === CALENDAR_MODE.DAY;
    },
    weeksOfTheCurrentMonth() {
      return this.getRangeOfDate(this.currentDate, 'month', 'weeks');
    },
    daysOfTheCurrentMonthByWeeks() {
      return this.weeksOfTheCurrentMonth.map((w) =>
        this.getRangeOfDate(w, 'week', 'days')
      );
    },
    daysOfTheCurrentWeek() {
      return this.getRangeOfDate(this.currentDate, 'week', 'days');
    },
    daysOfTheCurrentWeekByHours() {
      return DEFAULT_HOURS.map((hour) => ({
        hour,
        days: this.daysOfTheCurrentWeek,
      }));
    },
    dayHoursWithCurrentDate() {
      return DEFAULT_HOURS.map((hour) => ({
        hour,
        day: this.currentDate,
      }));
    },
    weekDaysByMode() {
      if (this.isCalendarModeWeek) {
        return DAYS_OF_WEEK.map((x) => ({
          ...x,
          date: this.$moment(this.daysOfTheCurrentWeek[x.day]).get('date'),
        }));
      }
      if (this.isCalendarModeDay) {
        const currentMoment = this.$moment(this.currentDate);
        return [
          {
            name: _.capitalize(currentMoment.format('dddd')),
            date: currentMoment.get('date'),
          },
        ];
      }
      return DAYS_OF_WEEK;
    },
  },
  methods: {
    getEventsByDay(day) {
      return (
        this.eventList.filter((s) =>
          this.$moment(s.dateFrom).isSame(day, 'day')
        ) ?? []
      );
    },
    isToday(day) {
      return this.$moment(day).isSame(this.$moment(), 'day');
    },
    showEvents(event) {
      this.$emit('open-event', event);
    },
    getEventsByDayAndHour(day, hour) {
      const dayWithHour = this.$moment(day).set('hour', hour.h);
      return (
        this.getEventsByDay(day).filter((event) => {
          const eventHourStart = this.$moment(
            event.hourStartFormatted,
            'HH:mm'
          );
          const eventStartWithHour = this.$moment(event.dateFrom).set(
            'hour',
            eventHourStart.get('hour')
          );
          const eventHourEnd = this.$moment(event.hourEndFormatted, 'HH:mm');
          const eventEndWithHour = this.$moment(event.dateFrom).set(
            'hour',
            eventHourEnd.get('hour')
          );
          const isSameAsStartTime = dayWithHour.isSameOrAfter(
            eventStartWithHour,
            'hour'
          );
          const isBeforeEndTime = dayWithHour.isSameOrBefore(
            eventEndWithHour,
            'hour'
          );
          return isSameAsStartTime && isBeforeEndTime;
        }) ?? null
      );
    },
    getRangeOfDate(date, granularity, rangeBy) {
      const startOf = this.$moment(date).startOf(granularity);
      const endOf = this.$moment(date).endOf(granularity);
      const range = this.$moment().range(startOf, endOf);
      return [...range.by(rangeBy)].map((d) => d.toDate());
    },
  },
};
</script>

<style scoped lang="scss">
.day-color {
  color: #ff7058;
  font-size: 14px;
}
.separator {
  color: #d0d0d0;
  border: solid 0.5px;
}
.calendar-container-header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;

  padding-left: 1px;
  padding-right: 1px;
  margin-left: 1px;
  margin-right: 1px;
  border-radius: 5px;
}
.calendar-container-week {
  display: flex;
  min-height: 75px;
}
.calendar-container-day-edit {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  text-align: center;
  padding: 10px 0 10px 0px;
  padding-right: 36px;
  min-height: 50px;
}
.calendar-container {
  border-radius: 8px;

  &__header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    background: #d9e5df;
    margin: 15px;
    padding: 10px;
    border-radius: 5px;
    span {
      font: Bold 14px/21px Lato;
      color: #11291b;
    }
  }
  &__body {
    margin: 15px 0px 0px 0px;
  }
  &__week {
    display: flex;
  }
  &__day {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    text-align: right;
    padding: 10px 0 10px 10px;
    padding-right: 10px;
    border: 1px solid #eeeeee;
    min-height: 150px;
  }

  &__weekViewHour {
    padding: 10px;
    width: 60px;
    border: 1px solid #eeeeee;
  }

  &__weekView {
    display: flex;
  }
  &__dayView {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    text-align: right;
    padding: 10px 0 10px 10px;
    padding-right: 10px;
    border: 1px solid #eeeeee;
    min-height: 100px;
  }
  .separator-line {
    background: #e2e2e2 !important;
    height: 1px !important;
  }
}
.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  background-color: #ff7058;
  border-radius: 50%;
  position: relative;
  bottom: 5px;
  color: white;
}
@media only screen and (max-width: 768px) {
  .calendar-container {
    &__day {
      text-align: center;
      min-height: auto;

      ::v-deep .event-circle {
        display: block;
      }

      ::v-deep .calendar-event-card {
        display: none;
      }

      ::v-deep .calendar-event-card-compact {
        display: none;
      }
    }
  }
}
</style>
