var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"calendar-container",style:(_vm.isPreview ? "background-color: #f7f7f7;" : "border: 1px solid #e2e2e2;")},[_c('div',{class:_vm.isPreview ? "calendar-container-header" : "calendar-container__header"},_vm._l((_vm.weekDaysByMode),function(item,index){return _c('span',{key:'calendar-day' + index},[_c('span',{staticClass:" day-color"},[_vm._v(_vm._s(item.name))]),(!_vm.isCalendarModeMonth)?_c('span',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(item.date)+" ")]):_vm._e()])}),0),(_vm.isPreview)?_c('hr',{staticClass:"ml-3 mr-3 separator"}):_vm._e(),_c('div',{staticClass:"calendar-container__body"},[(_vm.isCalendarModeMonth)?_vm._l((_vm.daysOfTheCurrentMonthByWeeks),function(week,i){return _c('div',{key:("week_" + i),class:_vm.isPreview
              ? "calendar-container-week ml-3"
              : "calendar-container__week "},_vm._l((week),function(day,j){return _c('div',{key:("day_" + j),class:_vm.isPreview
                ? "calendar-container-day-edit"
                : "calendar-container__day"},[_c('div',{class:_vm.isToday(day) && _vm.isPreview ? "circle mb-0" : ""},[_c('span',{style:(_vm.isPreview
                    ? "text-align: center; vertical-align: middle;"
                    : "")},[_vm._v(" "+_vm._s(day.getDate())+" ")])]),_c('CalendarEventCard',{directives:[{name:"b-modal",rawName:"v-b-modal.calendar-event-details-modal",modifiers:{"calendar-event-details-modal":true}}],attrs:{"events":_vm.getEventsByDay(day),"is-mobile":_vm.isMobile,"is-preview":_vm.isPreview},on:{"on-select-day-view":function($event){return _vm.$emit('on-select-day-view', day)},"on-select-event":function($event){_vm.selectedEventModalData = $event},"show-events":_vm.showEvents}})],1)}),0)}):(_vm.isCalendarModeWeek)?_vm._l((_vm.daysOfTheCurrentWeekByHours),function(ref,i){
                    var hour = ref.hour;
                    var days = ref.days;
return _c('div',{key:("hour_" + i),staticClass:"calendar-container__weekView"},[_c('div',{staticClass:"calendar-container__weekViewHour"},[_c('span',[_vm._v(_vm._s(hour.name))])]),_vm._l((days),function(day,j){return _c('div',{key:("day_" + j),staticClass:"calendar-container__dayView"},[_c('CalendarEventCard',{directives:[{name:"b-modal",rawName:"v-b-modal.calendar-event-details-modal",modifiers:{"calendar-event-details-modal":true}}],attrs:{"events":_vm.getEventsByDayAndHour(day, hour)},on:{"on-select-event":function($event){_vm.selectedEventModalData = $event},"show-events":_vm.showEvents}})],1)})],2)}):(_vm.isCalendarModeDay)?_vm._l((_vm.dayHoursWithCurrentDate),function(ref,i){
                    var hour = ref.hour;
                    var day = ref.day;
return _c('div',{key:("hour_" + i),staticClass:"calendar-container__weekView"},[_c('div',{staticClass:"calendar-container__weekViewHour"},[_c('span',[_vm._v(_vm._s(hour.name))])]),_c('div',{staticClass:"calendar-container__dayView"},[_c('CalendarEventCard',{directives:[{name:"b-modal",rawName:"v-b-modal.calendar-event-details-modal",modifiers:{"calendar-event-details-modal":true}}],attrs:{"events":_vm.getEventsByDayAndHour(day, hour)},on:{"on-select-event":function($event){_vm.selectedEventModalData = $event},"show-events":_vm.showEvents}})],1)])}):_vm._e()],2)]),(!_vm.isPreview)?_c('calendar-event-details-modal',{attrs:{"event":_vm.selectedEventModalData,"is-compliance-type":_vm.isComplianceType,"name":"calendar-event-details-modal"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }