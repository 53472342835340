<template>
  <div>
    <div v-if="showToggle">
      <b-button
        v-for="(value, key, i) in modes"
        :key="key"
        :class="{ 'ml-2': i > 0 }"
        :pressed="key === mode_sync"
        variant="light"
        @click="mode_sync = key"
      >
        {{ value }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { CALENDAR_MODE_DISPLAY } from '../constants';

export default {
  name: 'CalendarViewSelector',
  props: {
    mode: { type: String, default: null },
    showToggle: { type: Boolean, default: true },
  },
  data: () => ({
    modes: CALENDAR_MODE_DISPLAY,
  }),
  computed: {
    mode_sync: {
      get() {
        return this.mode;
      },
      set(value) {
        this.$emit('update:mode', value);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.btn {
  width: 6rem;
  &.active {
    background-color: #ff7058 !important;
    color: white !important;
  }
}
</style>
