<template>
  <div class="complaint-follow">
    <page-header
      :page="{
        icon: 'fas fa-user-circle',
        title: 'Seguimiento de Querellas',
        routes: [
          'Inicio',
          'Radicación de Querella',
          'Seguimiento de Querellas',
        ],
      }"
    >
      <template #customActions>
        <div class="d-block text-right">
          <action-button
            i="fas fa-arrow-circle-left"
            text="Regresar"
            variant="outline-success"
            @click="() => $router.push({ name: 'student-complaints_history' })"
          />
          <div class="my-2 rounded-pill px-3 py-2 gradient">
            <span class="font-weight-bold color-dark-gray">
              N&uacute;mero de Querella:
            </span>
            {{ complaintInfo.complaintNumber }}
          </div>
        </div>
      </template>
    </page-header>
    <board :details="detailsBoard" />
    <custom-tab class="custom-tab__extended">
      <custom-tab-item lazy title="Información General">
        <general-info :complaint-info="complaintInfo" />
      </custom-tab-item>
      <custom-tab-item
        v-if="!complaintInfo.hasRepresentativeLawyer"
        lazy
        title="Reuniones/Vistas"
      >
        <meeting-info :complaint-id="complaintId" />
      </custom-tab-item>
      <custom-tab-item
        v-if="!complaintInfo.hasRepresentativeLawyer"
        lazy
        title="Trámites"
      >
        <procedure-info :complaint-id="complaintId" />
      </custom-tab-item>
      <custom-tab-item
        v-if="!complaintInfo.hasRepresentativeLawyer"
        lazy
        title="Asuntos"
      >
        <affair-info :complaint-id="complaintId" />
      </custom-tab-item>
    </custom-tab>
  </div>
</template>

<script>
import Board from 'Board';
import PageHeader from 'PageHeader';
import ActionButton from 'ActionButton';
import CustomTab from 'custom-tabs/src/CustomTab';
import CustomTabItem from 'custom-tabs/src/CustomTabItem';
import {
  GeneralInfo,
  ProcedureInfo,
  AffairInfo,
  MeetingInfo,
} from '@/views/students/complaints-history/complaint-follow-tabs';
import {
  getComplaintDetail,
  getConciliator,
  getMunicipality,
} from '@/services/api/complaints.api';
import { capitalize } from '@/utils/filters';

export default {
  name: 'ComplaintFollow',
  components: {
    PageHeader,
    Board,
    CustomTab,
    CustomTabItem,
    ActionButton,
    GeneralInfo,
    ProcedureInfo,
    AffairInfo,
    MeetingInfo,
  },
  props: {
    studentSieId: {
      type: [String, Number],
      default: null,
    },
    complaintId: {
      type: [String, Number],
      default: null,
    },
  },
  data: () => ({
    complaintInfo: {},
    studentInfo: {},
  }),
  computed: {
    detailsBoard() {
      return [
        [
          {
            label: 'Determinación de Elegibilidad:',
            icon: 'fas fa-ballot-check',
            data: capitalize(this.studentInfo.disabilitie),
            cols: 'col-12 col-md',
          },
          {
            label: 'Alternativa de Ubicación:',
            icon: 'fas fa-ballot-check',
            data: capitalize(this.studentInfo.alternativeLocation),
            cols: 'col-12 col-md',
          },
          {
            label: 'Estatus:',
            icon: 'fas fa-ballot-check',
            data: capitalize(this.complaintInfo.status),
            cols: 'col-12 col-md',
          },
        ],
      ];
    },
  },
  async created() {
    await this.getComplaintInfo();
  },
  methods: {
    async getComplaintInfo() {
      const { data: response } = await getComplaintDetail(this.complaintId);
      const data = { ...response.data };
      const { data: conciliator } = await getConciliator(
        data.complaintDetail.conciliatorId
      );
      const { data: municipality } = await getMunicipality(
        data.studentDto.municipalityId
      );

      this.complaintInfo = {
        ...data.complaintDetail,
        conciliator,
        municipality,
        affairs: data.complaintAffairs,
        lawyers: data.lawyerDTOs,
        school: data.studentDto.school,
        region: data.studentDto.region,
        district: data.studentDto.district,
        schoolTypeName: data.studentDto.schoolTypeName,
      };
      this.studentInfo = {
        alternativeLocation: data.studentDto.alternativeLocation,
        disabilitie: data.studentDto.disabilitie,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables/colors.scss';

.gradient {
  background: transparent
    linear-gradient(
      90deg,
      map-get($colors, 'secondary-9') 0%,
      map-get($colors, 'white') 100%
    )
    0% 0% no-repeat padding-box;
}
.custom-tab__extended {
  margin-top: 1rem;
}
@media only screen and (min-width: 768px) {
  .custom-tab__extended ::v-deep {
    .tab-content {
      padding-top: 1rem;
    }
    .custom-tab-container {
      margin: 0;
      .nav-link {
        padding: 1rem 6rem !important;
      }
    }
  }
}
</style>
