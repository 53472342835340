import { http } from '@/core/api/';

const URL = 'api/complaint';

export const getComplaintsHistory = (studentSieNumber, pageIndex, pageSize) =>
  http.get(`${URL}`, {
    params: {
      studentSieNumber,
      pageIndex,
      pageSize,
    },
  });

export const getSettlementInitFilters = async (studentSieNumber) => {
  return await http.get(`${URL}/settlementinitfilters`, {
    params: { studentSieNumber },
  });
};

export const createComplaint = (params) =>
  http.post(`${URL}/save`, params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const getComplaintDetail = (complaintId) =>
  http.get(`${URL}/dataextended`, { params: { complaintId } });

export const getConciliator = (conciliatortId) =>
  http.get(`${URL}/conciliator`, { params: { conciliatortId } });

export const getMunicipality = (municipalityId) =>
  http.get(`${URL}/municipality`, { params: { municipalityId } });

export const getProcedures = (complaintId) =>
  http.get(`${URL}/procedures/${complaintId}`);

export const downloadDocument = (documentId) =>
  http.get(`${URL}/document/${documentId}`);

export const getComplaintsAffairs = (complaintId) =>
  http.get(`${URL}/affairs?complaintId=${complaintId}`);

export const getMeetings = (complaintId) =>
  http.get(`${URL}/meetings`, {
    params: {
      complaintId,
    },
  });

export const saveProcedure = (params) =>
  http.post(`${URL}/procedure/save`, params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const addLawyer = (params) => http.post(`${URL}/addlawyer`, params);

export const getLawyers = () => http.get(`${URL}/lawyers`);
