<template>
  <div class="details" :class="wrapperClass">
    <div
      v-for="(detail, idx) of details"
      :key="`section-${idx}`"
      :class="{ [sectionGutter]: details.length > 1 }"
    >
      <section-title v-if="detail.title" has-line :title="detail.title" />
      <slot :name="`custom-content-${idx + 1}`" />
      <div class="row mx-0">
        <div
          v-for="(info, index) of mappedInfo(detail)"
          :key="`info-${index}`"
          :class="`${info.class} ${colGutter}`"
        >
          <span class="data-title" :class="info.labelClass">
            {{ info.label }}
          </span>
          <span :class="info.dataClass">{{ info.data | empty }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionTitle from 'SectionTitle';
import { capitalize, date, price } from '@/utils/filters';
import filterName from '@/utils/constants/filterName';

export default {
  name: 'DetailGenerator',
  components: { SectionTitle },
  props: {
    wrapperClass: {
      type: [String, Object],
      default: () => '',
    },
    details: {
      type: Array,
      default: () => [],
    },
    colGutter: {
      type: String,
      default: () => 'mb-2 mb-md-0',
    },
    sectionGutter: {
      type: String,
      default: () => 'mb-4',
    },
  },
  computed: {
    mappedInfo() {
      return ({ info }) =>
        info
          ?.map((d) => {
            (d.filters || []).forEach((filter) => {
              switch (filter) {
                case filterName.date:
                  d.data = date(d.data);
                  break;
                case filterName.capitalize:
                  d.data = capitalize(d.data);
                  break;
                case filterName.price:
                  d.data = price(d.data);
                  break;
              }
            });
            return d;
          })
          .filter((d) => !d.hidden);
    },
  },
};
</script>

<style lang="scss" scoped>
.details {
  .data-title {
    display: block;
  }
}
</style>
