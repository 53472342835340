var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"procedure-form rounded bg-secondary-3 py-3 px-3 px-md-4"},[_c('icon-view',{attrs:{"has-border":false,"icon":"fas fa-file-plus color-orange","icon-size":"1rem","title":"Añadir Trámite","variant":"custom-icon-view"}}),_c('validation-observer',{ref:"procedureForm"},[_c('div',{staticClass:"row mx-0 align-items-center"},[_c('div',{staticClass:"col-12 col-md-4 mb-3"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('custom-drop-down',{attrs:{"clearable":false,"custom-error-msg":"Favor seleccione el tipo de trámite.","disabled":"","error":errors[0],"fieldkey":"id","fieldname":"name","label":"Tipo de Trámite:","options":_vm.options.procedures,"placeholder":"Seleccione"},model:{value:(_vm.model.type),callback:function ($$v) {_vm.$set(_vm.model, "type", $$v)},expression:"model.type"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-4 mb-3"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('custom-date-picker',{attrs:{"class-container":"","custom-error-msg":"La fecha es requerida.","error":errors[0],"label":"Fecha de Trámite:","max-date":new Date(),"placeholder":"DD/MM/YYYY"},model:{value:(_vm.model.procedureDate),callback:function ($$v) {_vm.$set(_vm.model, "procedureDate", $$v)},expression:"model.procedureDate"}})]}}])})],1),_c('div',{staticClass:"col-12 mb-3"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('custom-text-area',{attrs:{"id":"comments","count-classes":['d-block'],"custom-error-msg":"Favor escriba un comentario.","error":errors[0],"label":"Comentario:","maxlength":3000,"name":"comments","show-char-count":""},model:{value:(_vm.model.comments),callback:function ($$v) {_vm.$set(_vm.model, "comments", $$v)},expression:"model.comments"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-4 mb-3"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('custom-drop-down',{attrs:{"clearable":false,"custom-error-msg":"Favor seleccione el tipo de documento.","disabled":"","error":errors[0],"fieldkey":"id","fieldname":"name","label":"Tipo de Documento:","options":_vm.options.documentTypes,"placeholder":"Seleccione"},model:{value:(_vm.model.documentType),callback:function ($$v) {_vm.$set(_vm.model, "documentType", $$v)},expression:"model.documentType"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-4 mb-3"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('custom-file-input',{ref:"fileUpload",staticClass:"custom-document-input",attrs:{"accept":".pdf","custom-error-msg":"Favor anejar un archivo.","display-style":"normal","error":errors[0],"label":"Archivo:","label-position":"left","on-download":function () {},"required":""},model:{value:(_vm.model.file),callback:function ($$v) {_vm.$set(_vm.model, "file", $$v)},expression:"model.file"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-4 mb-3 mt-md-4 d-flex flex-column-reverse flex-md-row justify-content-around justify-content-md-end align-items-md-start"},[_c('action-button',{attrs:{"i":"fas fa-times-circle","text":"Cancelar","variant":"outline-danger mr-md-3"},on:{"click":function($event){return _vm.$emit('hide-form')}}}),_c('action-button',{attrs:{"i":"fas fa-save","text":"Guardar","variant":"success mb-2 mb-md-0"},on:{"click":_vm.saveHandler}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }