var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"complaint-follow"},[_c('page-header',{attrs:{"page":{
      icon: 'fas fa-user-circle',
      title: 'Seguimiento de Querellas',
      routes: [
        'Inicio',
        'Radicación de Querella',
        'Seguimiento de Querellas' ],
    }},scopedSlots:_vm._u([{key:"customActions",fn:function(){return [_c('div',{staticClass:"d-block text-right"},[_c('action-button',{attrs:{"i":"fas fa-arrow-circle-left","text":"Regresar","variant":"outline-success"},on:{"click":function () { return _vm.$router.push({ name: 'student-complaints_history' }); }}}),_c('div',{staticClass:"my-2 rounded-pill px-3 py-2 gradient"},[_c('span',{staticClass:"font-weight-bold color-dark-gray"},[_vm._v(" Número de Querella: ")]),_vm._v(" "+_vm._s(_vm.complaintInfo.complaintNumber)+" ")])],1)]},proxy:true}])}),_c('board',{attrs:{"details":_vm.detailsBoard}}),_c('custom-tab',{staticClass:"custom-tab__extended"},[_c('custom-tab-item',{attrs:{"lazy":"","title":"Información General"}},[_c('general-info',{attrs:{"complaint-info":_vm.complaintInfo}})],1),(!_vm.complaintInfo.hasRepresentativeLawyer)?_c('custom-tab-item',{attrs:{"lazy":"","title":"Reuniones/Vistas"}},[_c('meeting-info',{attrs:{"complaint-id":_vm.complaintId}})],1):_vm._e(),(!_vm.complaintInfo.hasRepresentativeLawyer)?_c('custom-tab-item',{attrs:{"lazy":"","title":"Trámites"}},[_c('procedure-info',{attrs:{"complaint-id":_vm.complaintId}})],1):_vm._e(),(!_vm.complaintInfo.hasRepresentativeLawyer)?_c('custom-tab-item',{attrs:{"lazy":"","title":"Asuntos"}},[_c('affair-info',{attrs:{"complaint-id":_vm.complaintId}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }