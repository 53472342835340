<template>
  <div class="my-4 mb-md-5 border-bottom pb-4">
    <span class="color-dark-gray font-14 d-block">Leyenda de Estatus</span>
    <div class="leyend">
      <div
        v-for="(status, idx) of data"
        :key="`status-${idx}`"
        class="status-block"
      >
        <div class="d-flex">
          <div class="bar" :style="{ 'background-color': status.primary }" />
          <div class="bar" :style="{ 'background-color': status.secondary }" />
        </div>
        <span class="font-12 color-gray">{{ status.label }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComplaintStatusLeyend',
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.leyend {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  .status-block {
    display: inline-block;
    width: 20%;
    margin-right: 0.2rem;
    .bar {
      padding: 0.25rem;
      width: 50%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .leyend {
    display: block;
    .status-block {
      width: 100%;
      margin-bottom: 0.5rem;
    }
  }
}
</style>
