<template>
  <div :class="isPreview ? `container-header ` : ``">
    <b-row class="pt-4">
      <b-col
        v-if="isPreview"
        class="text-center text-md-left mt-4 mt-md-0"
        cols="4"
        md="4"
        order="last"
        order-md="first"
      >
        <b-button
          class="adjust-position-left ml-3"
          variant="light"
          @click="pagerChange(forward)"
        >
          <i class="fas fa-chevron-left" />
        </b-button>
      </b-col>
      <b-col
        v-if="!isPreview"
        class="text-center text-md-left mt-4 mt-md-0"
        cols="12"
        :md="isPreview ? `3` : `4`"
        order="last"
        order-md="first"
      >
        <CalendarModeToggler
          :mode.sync="currentMode"
          :show-toggle="!isPreview"
        />
      </b-col>

      <b-col class="text-center mt-1" cols="4" md="4">
        <span
          class="h5 md-h3 text-dark font-weight-bold"
          v-text="currentDisplay"
        />
      </b-col>

      <b-col
        v-if="!isPreview"
        class="mt-2 mt-md-0"
        cols="12"
        :md="isPreview ? `3` : `4`"
      >
        <div class="d-flex justify-content-between justify-content-md-end">
          <CalendarCategoriesDropdown
            v-if="!isPreview"
            v-model="category"
            :items="categories"
          />
          <CalendarPager
            v-if="!isPreview"
            class="ml-md-4"
            @change="pagerChange"
          />
        </div>
      </b-col>
      <b-col
        v-if="isPreview"
        class="mt-4 mt-md-0  "
        cols="4"
        md="4"
        order="last"
        order-md="last"
      >
        <div class="d-flex justify-content-end">
          <b-button
            class="adjust-position mr-3"
            variant="light"
            @click="pagerChange(back)"
          >
            <i class="fas fa-chevron-right" />
          </b-button>
        </div>
      </b-col>
    </b-row>

    <b-row class="mt-4">
      <b-col cols="12">
        <CalendarBody
          :current-date="currentDate"
          :event-list="events"
          :is-compliance-type="isComplianceType"
          :is-mobile="isMobile"
          :is-preview="isPreview"
          :mode="currentMode"
          @on-select-day-view="onSelectDayViewHandler"
          @open-event="onShowEvents"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import {
  CalendarModeToggler,
  CalendarPager,
  CalendarCategoriesDropdown,
  CalendarBody,
} from './components';
import { eventsModule, eventsModuleName } from '@/store/modules';
import { CALENDAR_MODE, CALENDAR_PAGER_EVENTS } from './constants';
import eventCategories from '@/utils/constants/eventCategories.js';

const CATEGORY_IDS_TO_EXCLUDE = [
  eventCategories.CARGA_ASISTENCIA_BECA,
  eventCategories.CUMPLIMIENTO_RLV_CSEE,
  eventCategories.CUMPLIMIENTO_ACADEMICO,
  eventCategories.CUMPLIMIENTO_RLV_ESCOLAR,
  eventCategories.REUNION_ADMINISTRATIVA,
];

export default {
  name: 'Calendar',
  components: {
    CalendarModeToggler,
    CalendarPager,
    CalendarCategoriesDropdown,
    CalendarBody,
  },
  props: {
    eventList: {
      type: Array,
      default: null,
    },
    categoryList: {
      type: Array,
      default: null,
    },
    allowOffline: {
      type: Boolean,
      default: () => false,
    },
    isComplianceType: {
      type: Boolean,
      default: () => false,
    },
    isPreview: {
      type: Boolean,
      default: () => false,
    },
    isMobile: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    showPreviewDesign: false,
    issMobile: false,
    currentMode: CALENDAR_MODE.MONTH,
    category: null,
    back: CALENDAR_PAGER_EVENTS.BACK,
    forward: CALENDAR_PAGER_EVENTS.FORWARD,
    currentDate: moment()
      .startOf('date')
      .toDate(),
  }),
  computed: {
    categories() {
      const _categories =
        this.categoryList ||
        this.$store.getters[`${eventsModuleName}/getCategories`];
      return _categories.filter((x) => !CATEGORY_IDS_TO_EXCLUDE.includes(x.id));
    },
    events() {
      const _events = this.eventList;
      return _events;
    },
    currentDisplay() {
      return _.capitalize(this.$moment(this.currentDate).format('MMMM YYYY'));
    },
    startOfMonth() {
      return this.$moment(this.currentDate).startOf('month');
    },
  },
  methods: {
    ...eventsModule.mapActions(['getCategories', 'getEvents']),
    pagerChange(direction) {
      let method = null;
      let granularity = null;

      switch (direction) {
        case CALENDAR_PAGER_EVENTS.BACK:
          method = 'subtract';
          break;

        case CALENDAR_PAGER_EVENTS.FORWARD:
          method = 'add';
          break;
      }
      switch (this.currentMode) {
        case CALENDAR_MODE.MONTH:
          granularity = 'months';
          break;

        case CALENDAR_MODE.WEEK:
          granularity = 'weeks';
          break;

        case CALENDAR_MODE.DAY:
          granularity = 'days';
          break;
      }

      this.currentDate = this.$moment(this.currentDate)
        [method](1, granularity)
        .toDate();
    },
    async getEventsByMode() {
      const currentMoment = this.$moment(this.currentDate);

      const params = {
        year: currentMoment.get('year'),
        month: currentMoment.get('month') + 1,
        categoryId: this.category?.id,
      };

      switch (this.currentMode) {
        case CALENDAR_MODE.WEEK:
          params.week = currentMoment.get('week');
          break;
        case CALENDAR_MODE.DAY:
          params.day = currentMoment.get('date');
          break;
      }

      if (this.allowOffline) {
        this.$emit('on-get-events', params);
      } else {
        await this.getEvents(params);
      }
    },
    onShowEvents(event) {
      this.$emit('show-events', event);
    },
    onSelectDayViewHandler(day) {
      this.currentMode = CALENDAR_MODE.DAY;
      this.currentDate = day;
    },
  },
  mounted() {
    this.showPreviewDesign = this.isPreview;
  },
  watch: {
    async currentMode() {
      await this.getEventsByMode();
    },
    async currentDate() {
      await this.getEventsByMode();
    },
    async category() {
      await this.getEventsByMode();
    },
  },
  async created() {
    await Promise.all([this.getCategories(), this.getEventsByMode()]);
  },
};
</script>

<style scoped lang="scss">
.container-header {
  background-color: #f7f7f7;
  border-start-start-radius: 5px;
  border-start-end-radius: 5px;
}
.adjust-position {
  position: relative;
  //right: 24px;
  background-color: white;
  color: #ff7058;
}
.adjust-position-left {
  background-color: white;
  color: #ff7058;
}
</style>
