<template>
  <div class="procedure-form rounded bg-secondary-3 py-3 px-3 px-md-4">
    <icon-view
      :has-border="false"
      icon="fas fa-file-plus color-orange"
      icon-size="1rem"
      title="Añadir Trámite"
      variant="custom-icon-view"
    />
    <validation-observer ref="procedureForm">
      <div class="row mx-0 align-items-center">
        <div class="col-12 col-md-4 mb-3">
          <validation-provider v-slot="{ errors }" rules="required">
            <custom-drop-down
              v-model="model.type"
              :clearable="false"
              custom-error-msg="Favor seleccione el tipo de trámite."
              disabled
              :error="errors[0]"
              fieldkey="id"
              fieldname="name"
              label="Tipo de Trámite:"
              :options="options.procedures"
              placeholder="Seleccione"
            />
          </validation-provider>
        </div>
        <div class="col-12 col-md-4 mb-3">
          <validation-provider v-slot="{ errors }" rules="required">
            <custom-date-picker
              v-model="model.procedureDate"
              class-container=""
              custom-error-msg="La fecha es requerida."
              :error="errors[0]"
              label="Fecha de Trámite:"
              :max-date="new Date()"
              placeholder="DD/MM/YYYY"
            />
          </validation-provider>
        </div>
        <div class="col-12 mb-3">
          <validation-provider v-slot="{ errors }" rules="required">
            <custom-text-area
              id="comments"
              v-model="model.comments"
              :count-classes="['d-block']"
              custom-error-msg="Favor escriba un comentario."
              :error="errors[0]"
              label="Comentario:"
              :maxlength="3000"
              name="comments"
              show-char-count
            />
          </validation-provider>
        </div>
        <div class="col-12 col-md-4 mb-3">
          <validation-provider v-slot="{ errors }" rules="required">
            <custom-drop-down
              v-model="model.documentType"
              :clearable="false"
              custom-error-msg="Favor seleccione el tipo de documento."
              disabled
              :error="errors[0]"
              fieldkey="id"
              fieldname="name"
              label="Tipo de Documento:"
              :options="options.documentTypes"
              placeholder="Seleccione"
            />
          </validation-provider>
        </div>
        <div class="col-12 col-md-4 mb-3">
          <validation-provider v-slot="{ errors }" rules="required">
            <custom-file-input
              ref="fileUpload"
              v-model="model.file"
              accept=".pdf"
              class="custom-document-input"
              custom-error-msg="Favor anejar un archivo."
              display-style="normal"
              :error="errors[0]"
              label="Archivo:"
              label-position="left"
              :on-download="() => {}"
              required
            />
          </validation-provider>
        </div>
        <div
          class="col-12 col-md-4 mb-3 mt-md-4 d-flex flex-column-reverse flex-md-row justify-content-around justify-content-md-end align-items-md-start"
        >
          <action-button
            i="fas fa-times-circle"
            text="Cancelar"
            variant="outline-danger mr-md-3"
            @click="$emit('hide-form')"
          />
          <action-button
            i="fas fa-save"
            text="Guardar"
            variant="success mb-2 mb-md-0"
            @click="saveHandler"
          />
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import IconView from 'icon-view';
import CustomDropDown from 'custom-drop-down';
import CustomTextArea from 'CustomTextArea';
import CustomFileInput from 'CustomFileInput';
import CustomDatePicker from 'custom-date-picker';
import ActionButton from 'ActionButton';
import {
  procedureTypes,
  documentTypes,
} from '@/utils/constants/complaint/procedure.js';

export default {
  name: 'ProcedureForm',
  components: {
    IconView,
    CustomDropDown,
    CustomTextArea,
    CustomFileInput,
    CustomDatePicker,
    ActionButton,
  },
  props: {
    complaintId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    model: {
      procedureDate: null,
      documentType: null,
      type: null,
      file: null,
      comments: null,
    },
    options: {
      procedures: procedureTypes,
      documentTypes: documentTypes,
    },
  }),
  mounted() {
    this.initModel();
  },
  methods: {
    initModel() {
      this.model.type = this.options.procedures[0];
      this.model.documentType = this.options.documentTypes[0];
    },
    async saveHandler() {
      const valid = await this.$refs.procedureForm.validate();
      if (!valid) {
        this.$emit('display-alert');
        return;
      }
      this.$emit('save', this.model);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables/colors.scss';

.procedure-form ::v-deep {
  .custom-document-input {
    .input-label {
      font-weight: normal;
      display: inline-block;
    }
  }
  .custom-icon-view {
    margin-bottom: 1rem;
    .dot {
      .title-icon {
        margin: 0 !important;
      }
    }
    .font-16.color-gray-7 {
      font-size: 1rem !important;
      color: map-get($colors, 'dark-gray') !important;
    }
  }
}
</style>
