const meetingStatus = {
  Celebrada: 1,
  NoCelebrada: 2,
  Coordinada: 3,
  Transferida: 4,
  DejadaSinEfectoPorResolucion: 5,
};

export const meetingStatusOptions = [
  {
    id: meetingStatus.Celebrada,
    name: 'Celebrada',
    borderColor: '#2DB782',
    bgColor: '#F2FDF7',
    leyendIdx: 3,
  },
  {
    id: meetingStatus.NoCelebrada,
    name: 'No Celebrada',
    borderColor: '#FF5B5C',
    bgColor: '#FFF2F3',
    leyendIdx: 2,
  },
  {
    id: meetingStatus.Coordinada,
    name: 'Coordinada',
    borderColor: '#FDAC41',
    bgColor: '#FEF9EE',
    leyendIdx: 1,
  },
  {
    id: meetingStatus.Transferida,
    name: 'Transferida',
    borderColor: '#5E81F4',
    bgColor: '#EFF3FE',
    leyendIdx: 4,
  },
  {
    id: meetingStatus.DejadaSinEfectoPorResolucion,
    name: 'Dejada sin efecto por resolución',
    borderColor: '#814112',
    bgColor: '#F8F1E5',
    leyendIdx: 5,
  },
];

export default meetingStatusOptions;
