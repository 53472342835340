<template>
  <div class="general-info">
    <detail-generator col-gutter="mb-2 mb-md-3" :details="details">
      <template #custom-content-3>
        <div class="mx-1">
          <div
            v-for="(affair, idx) in complaintInfo.affairs"
            :key="`affair-${idx}`"
            class="rounded-pill d-inline-block border mx-2 my-1 px-3 py-2"
          >
            {{ affair.name | capitalize }}
          </div>
        </div>
      </template>
    </detail-generator>
    <div class="p-2 ml-n2">
      <section-title has-line title="Representación Legal" />
      <div class="ml-3 border__bottom--secondary-12 pb-3">
        <div class="color-gray font-14">
          ¿Estará asistido por un Abogado(a) en el manejo de la Querella?
        </div>
        <div class="color-black font-16">
          {{ complaintInfo.hasRepresentativeLawyer ? 'Sí' : 'No' }}
        </div>
      </div>
      <div
        v-for="(item, index) in complaintInfo.lawyers"
        :key="`lawyer-${index}`"
        class="mb-5 mt-2 ml-3"
      >
        <div class="row">
          <div class="col-md-3">
            <div class="color-gray font-14">Nombre de Abogado:</div>
            <div class="color-black font-16">
              {{ item.name }} {{ item.paternalLastName }}
              {{ item.maternalLastName }}
            </div>
          </div>
          <div class="col-md-3">
            <div class="color-gray font-14">RUA:</div>
            <div class="color-black font-16">{{ item.rua }}</div>
          </div>
          <div class="col-md-3">
            <div class="color-gray font-14">Correo Electrónico:</div>
            <div class="color-black font-16">{{ item.email }}</div>
          </div>
          <div class="col-md-3">
            <div class="color-gray font-14">Teléfono Celular:</div>
            <div class="color-black font-16">{{ item.cellular | phone }}</div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-3">
            <div class="color-gray font-14">Teléfono Oficina:</div>
            <div class="color-black font-16">{{ item.jobPhone | empty }}</div>
          </div>
          <div class="col-md-3">
            <div class="color-gray font-14">Extensión:</div>
            <div class="color-black font-16">
              {{ item.phoneExtension | empty }}
            </div>
          </div>
          <div class="col-md-3">
            <div class="color-gray font-14">Teléfono de Fax:</div>
            <div class="color-black font-16">{{ item.fax | empty }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionTitle from 'SectionTitle';
import DetailGenerator from '@/components/common/DetailGenerator.vue';
import filterName from '@/utils/constants/filterName';
import { capitalize, phone } from '@/utils/filters';

export default {
  name: 'ComplaintGeneralInfo',
  components: {
    SectionTitle,
    DetailGenerator,
  },
  filters: { capitalize, phone },
  props: {
    complaintInfo: {
      type: Object,
      required: true,
    },
  },
  computed: {
    details() {
      return [
        {
          title: 'Información General',
          info: [
            {
              label: 'Fecha de Radicación de la Querella:',
              data: this.complaintInfo.complaintDate,
              class: 'col-12 col-md-4',
            },
            {
              label: 'Número de Querella:',
              data: this.complaintInfo.complaintNumber,
              class: 'col-12 col-md-4',
            },
            {
              label: 'Estatus de la Querella:',
              data: this.complaintInfo.status,
              class: 'col-12 col-md-4',
              filters: [filterName.capitalize],
            },
            {
              label: 'Fecha de Expiración:',
              data: this.complaintInfo.expirationDate,
              class: 'col-12 col-md-4',
            },
            {
              label: 'Fecha de Contestación:',
              data: this.complaintInfo.replyDate,
              class: 'col-12 col-md-4',
            },
            {
              label: 'Fecha de Resolución Final:',
              data: this.complaintInfo.statusFinalResolutionDate,
              class: 'col-12 col-md-4',
            },
            {
              label: 'Conciliador:',
              data: this.complaintInfo.conciliator?.name,
              class: 'col-12 col-md-4',
              filters: [filterName.capitalize],
            },
            {
              label: 'Abogado DLEE',
              data: this.complaintInfo.lawyerDLEEName,
              class: 'col-12 col-md-4',
              filters: [filterName.capitalize],
            },
          ],
        },
        {
          title: 'Lugar donde el Estudiante Recibe Servicios Educativos',
          info: [
            {
              label: 'Lugar:',
              data: this.complaintInfo.schoolTypeName,
              class: 'col-12 col-md-4',
              filters: [filterName.capitalize],
            },
            {
              label: 'Región:',
              data: this.complaintInfo.region,
              class: 'col-12 col-md-4',
              filters: [filterName.capitalize],
            },
            {
              label: 'Distrito:',
              data: this.complaintInfo.district,
              class: 'col-12 col-md-4',
              filters: [filterName.capitalize],
            },
            {
              label: 'Municipio:',
              data: this.complaintInfo.municipality?.name,
              class: 'col-12 col-md-4',
              filters: [filterName.capitalize],
            },
            {
              label: 'Escuela:',
              data: this.complaintInfo.school,
              class: 'col-12 col-md-4',
              filters: [filterName.capitalize],
            },
          ],
        },
        { title: 'Asuntos' },
      ];
    },
  },
};
</script>
