<template>
  <div v-if="events.length > 0">
    <div
      v-if="isMobile || isPreview"
      class="event-circle"
      @click="isPreview ? onSelectEvent(events) : onSelectDayView()"
    >
      <div class="event-circle__container" />
    </div>
    <div v-if="!isPreview">
      <div
        v-for="(event, i) in events"
        :key="i"
        :class="
          hasManyItems ? 'calendar-event-card-compact' : 'calendar-event-card'
        "
        :style="{
          background: event.backgroundColor,
          borderLeft: `4px solid ${event.textColor}`,
        }"
        @click="onSelectEvent(event)"
      >
        <label :style="{ color: event.textColor }">
          {{ event.categoryName }}
        </label>

        <span v-if="!hasManyItems" :style="{ color: event.textColor }">
          <span>
            {{
              [event.hourStartFormatted, 'HH:mm'] | moment(HOUR_FORMAT_AM_PM)
            }}
          </span>
          <span v-if="event.hourEndFormatted">
            -
            <span>
              {{
                [event.hourEndFormatted, 'HH:mm'] | moment(HOUR_FORMAT_AM_PM)
              }}
            </span>
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { HOUR_FORMAT_AM_PM } from '../constants';

export default {
  name: 'CalendarEventCard',
  props: {
    events: { type: Array, default: () => [] },
    isPreview: { type: Boolean, default: () => false },
    isMobile: { type: Boolean, default: () => false },
  },
  data: () => ({ HOUR_FORMAT_AM_PM }),
  computed: {
    hasManyItems() {
      return this.events.length > 1;
    },
  },
  methods: {
    onSelectEvent(event) {
      this.$emit('on-select-event', { ...event });
      this.$emit('show-events', event);
    },
    onSelectDayView() {
      this.$emit('on-select-day-view');
    },
  },
};
</script>

<style scoped lang="scss">
.calendar-event-card {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 10px;
  min-height: 100%;
  margin-top: 10px;
  border-radius: 5px;
  label {
    cursor: pointer;
    user-select: none;
  }
  span {
    font-size: 0.5rem;
    user-select: none;
  }
}

.calendar-event-card-compact {
  cursor: pointer;
  border-radius: 5px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  text-align: left;
  label {
    cursor: pointer;
    margin-bottom: 0px;
    user-select: none;
    margin-left: 5px;
  }
}

.event-circle {
  //display: none;
  &__container {
    height: 15px;
    width: 15px;
    border-radius: 50px;
    background: #21b37b;
    margin: auto;
  }
}
</style>
