<template>
  <div>
    <b-row class="bg-secondary-9 rounded mx-2 pt-3">
      <b-col>
        <b-form-group label="Nombre del Estudiante:">
          <label class="text-muted" v-text="event.studentFullName" />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Número de SIE:">
          <label class="text-muted" v-text="event.studentSIE" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="ml-2 mt-2">
      <b-col cols="12">
        <b-form-group label="Fecha desde:">
          <label class="text-muted">
            <i class="far fa-calendar-day mr-1 text-orange" />
            {{ event.dateFrom | moment('DD/MMM/YYYY') }}
          </label>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group label="Hora:">
          <label class="text-muted">
            <i class="far fa-clock mr-1 text-orange" />
            {{
              [event.hourStartFormatted, 'HH:mm'] | moment(HOUR_FORMAT_AM_PM)
            }}
          </label>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group label="Vista / Reunión:">
          <label class="text-muted">
            <i class="fas fa-circle mr-1" :style="{ color: event.iconColor }" />
            {{ event.categoryName }}
          </label>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group label="Lugar de la Vista/Reunión:">
          <label class="text-muted" v-text="event.location" />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group label="Número de Querella:">
          <label class="text-muted" v-text="event.complaintNumber" />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group label="Persona que hace la Vista/Reunión:">
          <label class="text-muted" v-text="event.fullUserName" />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group label="Nombre del Abogado DLEE:">
          <label class="text-muted" v-text="event.plaintiffLawyerName || '-'" />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group label="Nombre del Abogado Representante:">
          <label class="text-muted" v-text="event.studentLawyersName || '-'" />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { HOUR_FORMAT_AM_PM } from '../../constants';
export default {
  name: 'ComplianceEventDetails',
  props: {
    event: {
      type: Object,
      default: null,
    },
  },
  data: () => ({ HOUR_FORMAT_AM_PM }),
};
</script>
