export default Object.freeze({
    CARGA_ASISTENCIA_BECA: 1,
    CALENDARIO_ACADEMICO: 2,
    CONCIENCIACION: 3,
    CUMPLIMIENTO_RLV_CSEE: 4,
    CUMPLIMIENTO_ACADEMICO: 5,
    CITA_EVALUACION: 6,
    ASSESSMENT: 7,
    CUMPLIMIENTO_RLV_ESCOLAR: 8,
    REUNION_ADMINISTRATIVA: 9,
    FERIADO: 10
});