<template>
  <b-row>
    <b-col cols="12">
      <b-form-group label="Nombre del Evento">
        <label class="text-muted" v-text="event.name" />
      </b-form-group>
    </b-col>
    <b-col cols="12">
      <b-form-group label="Categoría">
        <label class="text-muted">
          <i class="fas fa-circle mr-1" :style="{ color: event.textColor }" />
          {{ event.categoryName }}
        </label>
      </b-form-group>
    </b-col>
    <b-col cols="12" md="4">
      <b-form-group label="Fecha desde">
        <label class="text-muted">
          <i class="far fa-calendar-day mr-1 text-orange" />
          {{ event.dateFrom | moment('DD/MMM/YYYY') }}
        </label>
      </b-form-group>
    </b-col>
    <b-col cols="12" md="4">
      <b-form-group label="Hora desde">
        <label class="text-muted">
          <i class="far fa-clock mr-1 text-orange" />
          {{ [event.hourStartFormatted, 'HH:mm'] | moment(HOUR_FORMAT_AM_PM) }}
        </label>
      </b-form-group>
    </b-col>
    <b-col cols="12" md="4">
      <b-form-group label="Hora hasta">
        <label class="text-muted">
          <i class="far fa-clock mr-1 text-orange" />
          {{ [event.hourEndFormatted, 'HH:mm'] | moment(HOUR_FORMAT_AM_PM) }}
        </label>
      </b-form-group>
    </b-col>
    <b-col cols="12">
      <b-form-group label="Descripción">
        <label class="text-muted" v-text="event.description" />
      </b-form-group>
    </b-col>
    <b-col cols="12">
      <b-form-group label="Localización">
        <label class="text-muted" v-text="event.location" />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { HOUR_FORMAT_AM_PM } from '../../constants';
export default {
  name: 'CommonEventDetails',
  props: {
    event: {
      type: Object,
      default: null,
    },
  },
  data: () => ({ HOUR_FORMAT_AM_PM }),
};
</script>
