<template>
  <div class="affair-info">
    <section-title has-line title="Lista de Asuntos" />
    <div
      v-for="(affair, idx) of affairs"
      :key="`affair-${idx}`"
      class="pt-3 px-3 pb-2 bg-gray-3 rounded mb-3"
    >
      <detail-generator :details="mappedAffair(affair)" />
      <div class="rounded bg-white p-3 mt-3">
        <icon-view
          :has-border="false"
          icon="fas fa-info-circle color-orange"
          icon-size="1rem"
          title="Información Adicional"
          variant="custom-icon-view"
        />

        <detail-generator
          :details="additionalInfo(affair)"
          wrapper-class="ml-2 mx-2"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SectionTitle from 'SectionTitle';
import IconView from 'icon-view';
import DetailGenerator from '@/components/common/DetailGenerator.vue';
import { getComplaintsAffairs } from '@/services/api/complaints.api';

export default {
  name: 'ComplaintAffairInfo',
  components: { IconView, DetailGenerator, SectionTitle },
  props: {
    complaintId: {
      type: Number,
      default: () => 0,
    },
  },
  data: () => ({
    affairs: [],
  }),
  computed: {
    mappedAffair() {
      return (row) => {
        let affair = [
          {
            label: 'Tipo de Asunto:',
            data: row.affair.name,
            class: 'col-12 col-md',
          },
          {
            label: 'Estatus del Asunto:',
            data: row.agreeStatus?.affairAgreeStatusName,
            class: 'col-12 col-md',
          },
        ];
        return [{ info: affair }];
      };
    },
    additionalInfo() {
      return (row) => {
        let info = [
          {
            label: 'Reunión',
            data: row.complaintMettingName,
            class: 'col-12 col-md-4',
          },
          {
            label: 'Fecha:',
            data: row.affairAgreeDate,
            class: 'col-12 col-md-4',
          },
          {
            label:
              '¿Autorización para Divulgar Información Confidencial del Acuerdo?',
            data: row.authorizeForAdministrativeHearing ? 'Si' : 'No',
            class: 'col-12 col-md-4',
          },
        ];
        return [{ info }];
      };
    },
  },
  async created() {
    await this.getAffairs();
  },
  methods: {
    async getAffairs() {
      const { data } = await getComplaintsAffairs(this.complaintId);
      this.affairs = data;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables/colors.scss';

.affair-info ::v-deep {
  .data-title {
    display: block;
  }
  .custom-icon-view {
    margin-bottom: 0.5rem;
    .dot {
      box-shadow: none !important;
      margin-right: 0.5rem !important;
      .title-icon {
        margin: 0 !important;
      }
    }
    .font-16.color-gray-7 {
      font-size: 1rem !important;
      color: map-get($colors, 'dark-gray') !important;
    }
  }
}

@media only screen and (max-width: 768px) {
  .custom-map-btn {
    width: 100% !important;
  }
}
</style>
