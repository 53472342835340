<template>
  <div>
    <procedure-form
      v-if="formToggle"
      :complaint-id="Number(complaintId)"
      @hide-form="formVisibilityHandler"
      @save="saveProcedureHandler"
    />
    <div v-else class="text-right">
      <action-button
        i="fas fa-file-plus"
        text="Añadir Trámite"
        variant="success mr-md-5"
        @click="formVisibilityHandler"
      />
    </div>
    <div v-for="(item, index) in procedures" :key="`procedure-row-${index}`">
      <custom-callout class-main="py-3 mt-3" :has-toggle="false">
        <div class="bg-secondary-3">
          <div class="row border-bottom mr-md-4 ml-md-n3 mx-0 pb-2">
            <div class="col-12 col-md-10 row">
              <div class="col-12 col-md-3 mb-2 mb-md-0">
                <div class="color-gray font-14">Número del Trámite:</div>
                <div class="color-black font-16">{{ item.id }}</div>
              </div>
              <div class="col-12 col-md-4 mb-2">
                <div class="color-gray font-14">Fecha de Trámite:</div>
                <div class="color-black font-16">
                  {{ item.procedureDate }}
                </div>
              </div>
              <div class="col-12 col-md-5 mb-2 mb-md-0">
                <div class="color-gray font-14">Tipo de Trámite:</div>
                <div class="color-black font-16">
                  {{ item.procedureTypeDescription }}
                </div>
              </div>
              <div class="offset-md-3 col">
                <div class="color-gray font-14">Tipo de Documento:</div>
                <div class="color-black font-16">
                  {{ item.procedureTypeDocument }}
                </div>
              </div>
            </div>
            <div
              v-if="item.documentAttachmentId"
              class="col d-flex flex-column flex-md-row align-items-md-center justify-content-md-end mt-2 mt-md-0"
            >
              <action-button
                i="fas fa-file-search"
                text="Ver Documento"
                variant="outline-success d-block"
                @click="onDownloadHandler(item.documentAttachmentId)"
              />
            </div>
          </div>
          <div class="row mt-3">
            <div class="col">
              <div class="color-gray font-14">Comentario:</div>
              <div class="color-black font-16">
                {{ item.comment }}
              </div>
            </div>
          </div>
        </div>
      </custom-callout>
    </div>
  </div>
</template>

<script>
import CustomCallout from 'custom-callout';
import ActionButton from 'ActionButton';
import alert from 'utils/mixins/alert.mixin';
import { serialize } from 'object-to-formdata';
import ProcedureForm from '@/views/students/complaints-history/components/ProcedureForm';
import { downloadBlobToFile } from '@/utils/blob';
import {
  getProcedures as _getProcedures,
  downloadDocument as _downloadDocument,
  saveProcedure,
} from '@/services/api/complaints.api';

export default {
  name: 'ProcedureInfo',
  components: {
    CustomCallout,
    ActionButton,
    ProcedureForm,
  },
  mixins: [alert],
  props: {
    complaintId: {
      type: [Number, String],
      required: true,
    },
  },
  data: () => ({
    procedures: [],
    formToggle: false,
  }),
  async mounted() {
    await this.getProcedures();
  },
  methods: {
    async getProcedures() {
      if (this.complaintId) {
        this.procedures = (await _getProcedures(this.complaintId)).data;
      }
    },
    async onDownloadHandler(documentId) {
      const { data } = await _downloadDocument(documentId);
      if (data) {
        downloadBlobToFile(data);
      }
    },
    async saveProcedureHandler(procedure) {
      const answer = await this.ShowCancelModal(
        'Atención',
        '¿Está seguro que desea someter una Moción?',
        'Si',
        'No'
      );
      if (!answer) return;

      const params = serialize(
        {
          complaintId: this.complaintId,
          procedureTypeId: procedure.type.id,
          documentTypeId: procedure.documentType.id,
          procedureDate: procedure.procedureDate,
          comment: procedure.comments,
          document: procedure.file,
        },
        { indices: true, nullsAsUndefineds: true }
      );
      const { data: res } = await saveProcedure(params);

      if (res.errors?.length) {
        res.errors.forEach((error) => {
          this.ShowToast('Alerta', error, 'error');
        });
        return;
      }

      if (res.message && res.data == null) {
        this.ShowToast('Alerta', res.message, 'error');
        return;
      }

      this.ShowToast(
        'Proceso Exitoso',
        'La moción ha sido sometida exitosamente.',
        'success'
      );
      this.formVisibilityHandler();
      await this.getProcedures();
    },
    formVisibilityHandler() {
      this.formToggle = !this.formToggle;
    },
  },
};
</script>
