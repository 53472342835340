export const DEFAULT_HOUR_FORMAT = 'hh:mm';

export const HOUR_FORMAT_AM_PM = 'hh:mm A';

export const CALENDAR_MODE = Object.freeze({
  MONTH: 'MONTH',
  WEEK: 'WEEK',
  DAY: 'DAY',
});

export const CALENDAR_MODE_DISPLAY = Object.freeze({
  MONTH: 'Mes',
  WEEK: 'Semana',
  DAY: 'Día',
});

export const DAYS_OF_WEEK = Object.freeze([
  { name: 'L', day: 0 },
  { name: 'M', day: 1 },
  { name: 'M', day: 2 },
  { name: 'J', day: 3 },
  { name: 'V', day: 4 },
  { name: 'S', day: 5 },
  { name: 'D', day: 6 },
]);

export const DEFAULT_HOURS = Object.freeze([
  { name: '7am', h: '7' },
  { name: '8am', h: '8' },
  { name: '9am', h: '9' },
  { name: '10am', h: '10' },
  { name: '11am', h: '11' },
  { name: '12am', h: '12' },
  { name: '1pm', h: '13' },
  { name: '2pm', h: '14' },
  { name: '3pm', h: '15' },
  { name: '4pm', h: '16' },
  { name: '5pm', h: '17' },
  { name: '6pm', h: '18' },
  { name: '7pm', h: '19' },
  { name: '8pm', h: '20' },
]);

export const CALENDAR_PAGER_EVENTS = Object.freeze({
  BACK: 'BACK',
  FORWARD: 'FORWARD',
});
