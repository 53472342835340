<template>
  <div class="complaint-metting">
    <leyend :data="statusLeyendData" />
    <ui-calendar
      allow-offline
      :category-list="categories"
      :event-list="meetings"
      is-compliance-type
      @on-get-events="onGetEventsHandler"
    />
  </div>
</template>

<script>
import moment from 'moment';
import UiCalendar from '@/components/ui/UiCalendar';
import { getMeetings as _getMeetings } from '@/services/api/complaints.api';
import meetingStatus from '@/utils/constants/complaint/meetingStatus.js';
import Leyend from '@/views/students/complaints-history/components/ComplaintStatusLeyend.vue';

export default {
  name: 'MeetingInfo',
  components: {
    UiCalendar,
    Leyend,
  },
  props: {
    complaintId: {
      type: [Number, String],
      required: true,
    },
  },
  data: () => ({
    meetingTypes: [],
    meetings: [],
    meetingsOriginal: [],
  }),
  computed: {
    categories() {
      return meetingStatus;
    },
    statusLeyendData() {
      return meetingStatus
        .sort((a, b) => a.leyendIdx - b.leyendIdx)
        .map((s) => ({
          label: s.name,
          primary: s.borderColor,
          secondary: s.bgColor,
        }));
    },
  },
  async mounted() {
    await this.getMeetings();
  },
  methods: {
    getStatusById(id) {
      return meetingStatus.find((x) => x.id === id);
    },
    async getMeetings() {
      if (this.complaintId) {
        const data = (await _getMeetings(this.complaintId)).data;
        if (data) {
          const _mapped = data.map((x) => ({
            ...x,
            backgroundColor: this.getStatusById(x.meetingStatusId)?.bgColor,
            iconColor: this.getStatusById(x.meetingStatusId)?.borderColor,
            textColor: this.getStatusById(x.meetingStatusId)?.borderColor,
            categoryId: x.meetingStatusId,
            categoryName: x.title,
            dateFrom: moment(x.start, 'YYYY-MM-DDTHH:mm:ss'),
            hourStartFormatted: moment(x.start).format('HH:MM'),
            location: x.meetingLocationName,
          }));
          this.meetingsOriginal = this.meetings = _mapped;
        }
      }
    },
    onGetEventsHandler(params) {
      if (params?.categoryId) {
        this.meetings = this.meetingsOriginal.filter(
          (x) => x.categoryId === params.categoryId
        );
      } else {
        this.meetings = this.meetingsOriginal;
      }
    },
  },
};
</script>
