export const procedureTypes = [
  {
    id: 16,
    name: 'Moción',
  },
];

export const documentTypes = [
  {
    id: 29,
    name: 'Moción',
  },
];
