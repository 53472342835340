<template>
  <div>
    <b-button variant="light" @click="moveBack">
      <i class="fas fa-chevron-left" />
    </b-button>
    <b-button class="ml-2" variant="light" @click="moveForward">
      <i class="fas fa-chevron-right" />
    </b-button>
  </div>
</template>

<script>
import { CALENDAR_PAGER_EVENTS } from '../constants';

export default {
  name: 'CalendarMonthPager',
  methods: {
    moveBack() {
      this.$emit('change', CALENDAR_PAGER_EVENTS.BACK);
    },
    moveForward() {
      this.$emit('change', CALENDAR_PAGER_EVENTS.FORWARD);
    },
  },
};
</script>
