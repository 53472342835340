<template>
  <b-modal v-if="name && event" :id="name" hide-footer size="lg">
    <template #modal-title>
      <div
        class="d-flex align-items-center rounded-pill py-1 pr-4"
        :style="{
          background:
            'linear-gradient(90deg, rgba(2, 0, 36, 0) 10%, rgb(0, 0, 0) 600%)',
        }"
      >
        <b-avatar class="bg-white shadow mr-2">
          <i class="fas fa-calendar-alt text-orange" />
        </b-avatar>
        <span class="h3 mb-0 font-weight-bold">Información del Evento</span>
      </div>
    </template>

    <template #modal-header-close>
      <b-button pill variant="outline-success">
        <i class="fas fa-window-close" />
        Cerrar
      </b-button>
    </template>

    <compliance-event-details v-if="isComplianceType" :event="event" />
    <common-event-details v-else :event="event" />
  </b-modal>
</template>

<script>
import { HOUR_FORMAT_AM_PM } from '../constants';
import CommonEventDetails from './event-details/CommonEventDetails.vue';
import ComplianceEventDetails from './event-details/ComplianceEventDetails.vue';

export default {
  name: 'CalendarEventDetailsModal',
  components: {
    CommonEventDetails,
    ComplianceEventDetails,
  },
  props: {
    name: { type: String, default: null },
    event: { type: Object, default: null },
    isComplianceType: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({ HOUR_FORMAT_AM_PM }),
};
</script>
