<template>
  <b-dropdown :text="buttonText" variant="outline-success">
    <b-dropdown-item
      v-for="(item, i) in itemsWithDefaultItem"
      :key="i"
      @click="value_proxy = item"
    >
      {{ item.name }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  name: 'CalendarFilter',
  props: {
    items: { type: Array, default: () => [] },
    value: { type: Object, default: null },
  },
  data: () => ({
    current: null,
  }),
  computed: {
    value_proxy: {
      get() {
        return this.value;
      },
      set(value) {
        this.current = value.id ? value : null;
        this.$emit('input', this.current);
      },
    },
    buttonText() {
      return this.current?.name ?? 'Categorías';
    },
    itemsWithDefaultItem() {
      return [{ id: null, name: 'Todos' }, ...this.items];
    },
  },
};
</script>
